<template>
     <div className="animated fadeIn">
       <div id="snackbar"></div>
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of all Organizations
        <div class="card-header-actions">
          
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
          <div slot="OrganizationIsActive" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.OrgIsActive" variant="3d" label v-bind="labelIcon" @change.native="changeStatus(props.row.OrganizationID)"/>
          </div>

          <div slot="OrganizationID" slot-scope="props">
            <i class="fa fa-edit" @click="editOrganization(props.row.OrganizationID)"></i>
          </div>          
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>    
</template>
<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import MasterService from "@/services/MasterService";
import OrganizationService from "@/services/OrganizationService";
// Vue.use(ClientTable);
export default {
  name: "AllMovies",
  components: {
    Event,
    cSwitch
  },
  data: function() {
    return {
      columns: ["OrganizationName", "OrganizationIsActive", "OrganizationID"],
      data: [],
      options: {
        headings: {
          name: "Organization Name",
          OrganizationIsActive: "Status",
          edit: "Edit"
        },
        sortable: ["OrganizationName"],
        filterable: ["OrganizationName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  mounted() {
    this.getOrganizationList();
  },
  methods: {
    getOrganizationList: function() {
      this.data = [];
      OrganizationService.getOrganization()
        .then(response => {
          const { data } = response;
          if (data.Status && data.OrganizationList.length > 0) {
            this.data = data.OrganizationList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editOrganization: function(orgID) {
      this.$router.push({
        path: "/org-management/organization/add-org",
        query: { orgID }
      });
    },
    changeStatus: function(ID) {
      let payload = {
        TableName: "Organization",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
